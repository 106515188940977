@import 'ngx-toastr/toastr'; /* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

#dropdown {
  position: absolute;
  z-index: 50 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.material-symbols-outlined {
  color: #8f9bb3;
  font-size: 19px;
  font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 40
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1.1) translateY(10px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px) scale(0.9);
  }
}

/*.toast-outlined {
  animation: bounceIn 0.6s ease-out, slideOut 0.4s ease-in 1.6s forwards;
}*/



@layer base {
  :root {
    --background: hsl(0, 0%, 100%);
    --foreground: hsl(222, 47%, 11%);
    --card: hsl(210, 40%, 96%);
    --card-foreground: hsl(240, 0%, 0%);
    --primary:#e3a602;
    --primary-foreground: hsl(0, 0%, 100%);
    --muted: hsl(213, 27%, 84%);
    --muted-foreground: hsl(215, 16%, 47%);
    --destructive: hsl(350, 100%, 40%);
    --destructive-foreground: hsl(0, 0%, 98%);
    --border: hsl(214, 32%, 91%);
  }
}
.iti {
  position: relative;
  display: block !important;
}
.iti--separate-dial-code .iti__selected-dial-code {
  display: none;
}
